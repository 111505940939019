//Interior-----------------------------------------------------------
export const Interior = [
  {
    id: 1,
    name: "New Orleans, Louisiana",
    cover: "./images/interior/9.png",
  },
  {
    id: 2,
    name: "Jerrsy, United State",
    cover: "./images/interior/3.jpeg",
  },
  {
    id: 3,
    name: "Liverpool, London",
    cover: "./images/interior/10.png",
  },
  {
    id: 4,
    name: "NewYork, United States",
    cover: "https://www.99acres.com/microsite/articles/files/2023/11/scandinavian_interior_design-5d052b73-7f6c-45f3-bcb8-ee5d229bbe11.jpg",
  },
  {
    id: 5,
    name: "Montreal, Canada",
    cover: "https://thedesignbasics.com.au/wp-content/uploads/2020/10/GraceIInteriorDesigns-interiordesignermelbourne_StKildaEast_0936.jpg",
  },
  {
    id: 6,
    name: "California, USA",
    cover: "./images/interior/6.jpeg",
  },
]

//modularkitchen-----------------------------------------------------------
export const ModularKitchen = [
  {
    id: 1,
    name: "New Orleans, Louisiana",
    cover: "https://4.imimg.com/data4/OX/YU/MY-24297412/indian-modern-modular-kitchen-500x500.jpg",
  },
  {
    id: 2,
    name: "Jerrsy, United State",
    cover: "./images/14.jpeg",
  },
  {
    id: 3,
    name: "Liverpool, London",
    cover: "https://images.unsplash.com/photo-1556912172-45b7abe8b7e1",
  },
  {
    id: 4,
    name: "NewYork, United States",
    cover: "https://assets-news.housing.com/news/wp-content/uploads/2022/01/25212810/L-shape-modular-kitchen-design-12-Indian-designs-that-can-transform-your-kitchen-FB-1200x700-compressed.jpg",
  },
  {
    id: 5,
    name: "Montreal, Canada",
    cover: "https://www.regalokitchens.com/images/classical-trendy-modular-kitchen-design.jpg",
  },
  {
    id: 6,
    name: "California, USA",
    cover: "https://images.woodenstreet.de/image/data/modular%20kitchen/22.jpg",
  },
]

//Planning-----------------------------------------------------------
export const Planning = [
  {
    id: 1,
    name: "New Orleans, Louisiana",
    cover: "./images/9.jpeg",
  },
  {
    id: 2,
    name: "NewYork, United States",
    cover: "https://www.homeguide.com.sg/wp-content/uploads/2019/02/Space-Planning-in-an-HDB-Flat-Most-Important-Things-to-Consider.png",
  },
  {
    id: 3,
    name: "Jerrsy, United State",
    cover: "https://delecondesigns.com/images/commercial.jpg",
  },
  {
    id: 4,
    name: "Liverpool, London",
    cover: "./images/11.jpeg",
  },
  {
    id: 5,
    name: "Montreal, Canada",
    cover: "https://www.latestinteriors.com/wp-content/uploads/2023/01/Commercial.jpg",
  },
  {
    id: 6,
    name: "California, USA",
    cover: "./images/9.jpeg",
  },
]
import React from 'react'
import Heading from "../../common/Heading"
import { commercial } from "../../data/Data"
import "./style.css"

const Commercial = () => {
  return (
    <div>
      <section className='location padding'>
        <div className='container'>
          <Heading title='Commercial' subtitle='' />
         <p className="commercial-text" style={{ textAlign: 'justify' }}>
  Our team of architects and designers create functional and visually appealing commercial spaces. We cater to a variety of design needs, from office spaces to restaurants and retail stores.
</p>

          <div className='content grid3 mtop mobile-view'>
            {commercial.map((item, index) => (
              <div className='box' key={index}>
                <img src={item.cover} alt='' />
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  )
}

export default Commercial

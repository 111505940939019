import React from "react"
import Heading from "../../common/Heading"
import { location } from "../../data/Data"
import "./style.css"

const Location = () => {
  return (
    <>
      <section className='location padding'>
        <div className='container'>
          <Heading title='Residential' subtitle='' />
          <p className="commercial-text" style={{ textAlign: 'justify'}}>
            Take a look at our residential projects, each one tailored to the unique needs and preferences of our clients. Whether you're renovating or building a new home, we're here to assist you.
          </p>

          <div className='content grid3 mtop mobile-view'>
            {location.map((item, index) => (
              <div className='box' key={index}>
                <img src={item.cover} alt='' />
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  )
}

export default Location

import React from "react"
import Hero from "./hero/Hero"
import About from "../about/About"
// import SliderComponent from "./slider img/SliderComponent"

import Awards from "./Why us/Awards"
import Location from "./Gallery/Location"
import Recent from "./Services/Recent"
import Team from "./testimonials/Team"
import Commercial from "./Gallery/Commercial"



const Home = () => {
  return (
    <>

      <Hero />
      <About />
      {/* <SliderComponent /> */}
      <Recent />
      <Awards />
      <Location />
      <Commercial />
      <Team />
    
      
    </>
  )
}

export default Home

import React from "react";
import Heading from "../../common/Heading";
import { team } from "../../data/Data";
import "./team.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Team = () => {
const settings = {
  dots: false,
  infinite: true,
  arrows: false,// Remove next and previous buttons
  speed: 2000,
  slidesToShow: window.innerWidth <= 768 ? 1 : 3,
  slidesToScroll: window.innerWidth <= 768 ? 1 : 2,
  autoplay: true, // Enable autoplay
  autoplaySpeed: 3000 // Set autoplay speed in milliseconds (e.g., 3000 = 3 seconds)
};

  return (
    <>
      <section className='team background'>
        <div className='container '>
          <Heading title='Our Clients' subtitle='' />

          <Slider {...settings} className="team-slider">
            {team.map((val, index) => (
              <div className='box' key={index}>
                <div className='details'>
                  <div className='img'>
                    <img src={val.cover} alt='' />
                    {/* <i className='fa-solid fa-circle-check'></i> */}
                  </div>
                  <h4>{val.name}</h4>
                  <label>{val.testimonial}</label>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </section>
    </>
  );
};

export default Team;

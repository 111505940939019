import React, { useState, useRef, useEffect } from 'react';
import img from "../images/1.jpeg";
import Back from "../common/Back";
import "./contact.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelope, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const form = useRef();

  useEffect(() => {
    if (form.current) {
      form.current.reset();
    }
  }, []);

  const validateEmail = email => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePhone = phone => {
    const re = /^\d{10}$/;
    return re.test(String(phone));
  };

  const handleSubmit = e => {
    if (!name || !email || !phone || !message) {
      e.preventDefault();
      alert('Please fill out all fields before submitting.');
    } else if (!validateEmail(email)) {
      e.preventDefault();
      alert('Please enter a valid email.');
    } else if (!validatePhone(phone)) {
      e.preventDefault();
      alert('Please enter a valid phone number.');
    } else {
      form.current.reset();
    }
  };

  return (
    <>
      <Back name='' title='Get Help & Friendly Support' cover={img} />
      <section className='contact mb'>
        <div className='container'>
          <div className="row">
            <div className="col-lg-6">

              <div className="contact-info">
                <h5><FontAwesomeIcon icon={faPhone} className="icon" /> Mobile No.:</h5>
                <p className="phone-number"><a href="tel:+91-9971400633">+91-9971400633</a></p>
                <p className="phone-number"><a href="tel:+91-9899953669">+91-9899953669</a></p>
              </div>
              <div className="contact-info email">
                <h5><FontAwesomeIcon icon={faEnvelope} className="icon" /> E-Mail ID:</h5>
                <p className="email-link"> <a href="mailto:Mathurpooja26@Gmail.Com" >mathurpooja26@gmail.Com</a></p>
                <p className="email-link"><a href="mailto:Aakar.Design26@Gmail.Com" >aakar.design26@gmail.Com</a></p>
              </div>
              <div className="contact-info address">
                <h5><FontAwesomeIcon icon={faMapMarkerAlt} className="icon" /> Address:</h5>
                <p className="address-info">504, B wing, Statesman House, 148 Barakhamba
                  Road, New Delhi (INDIA)</p>
                <p className="address-info">GSTIN/UIN: 07ASBPM1542Q2ZG</p>
              </div>
            </div>
            <div className="col-lg-6">

              <form className='shadow' action="https://aakardesignconsultants.in/enquiry.php" method="POST" onSubmit={handleSubmit} ref={form}>
                <h4>Enquiry</h4> <br />
                <input type='text' name='name' placeholder='Name' value={name} onChange={e => setName(e.target.value)} />
                <input type='text' name='email' placeholder='Email' value={email} onChange={e => setEmail(e.target.value)} />
                <input type='number' name='phone' placeholder='Phone no' value={phone} onChange={e => setPhone(e.target.value)} />
                <textarea cols='30' rows='10' name='message' placeholder='Message' value={message} onChange={e => setMessage(e.target.value)} />
                <button type='submit' name='SubmitEmail'>Submit Enquiry</button>
              </form>

            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-lg-12">
              <p><iframe title="Google Map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3501.9743547998287!2d77.2226786!3d28.630530699999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfd36b668e3cf%3A0xdac5a51e96f83727!2sNational%20Internet%20Exchange%20of%20India!5e0!3m2!1sen!2sin!4v1713616267193!5m2!1sen!2sin" width="100%" height={350} style={{ border: 0 }} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" /></p>

            </div>
          </div>
        </div>

      </section>
    </>
  );
};

export default Contact;

import React from "react"
// import { footer } from "../../data/Data"
import "./footer.css"
import { Link } from 'react-router-dom';
import image from "../footer/image.png"
<Link to="/contact">Contact Us Today</Link>


const Footer = () => {
  return (
    <>
      <div>
        <footer className="footer">
          <div className="footer_overlay">
            <div className="container">
              <div className="row">
                {/* col start */}
                <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                  <div className="footer_about_div">
                    <div className="footer_logo_div">
                      <a href="/"><img src={image} alt="" className="img-fluid" /></a>
                    </div>
                    <p style={{ textAlign: 'justify' }}>Aakar Design Consultants is a renowned architecture and interior design firm known for its innovative and contemporary designs. <br /> <a className="readmore" href="about">Read More</a></p>

                  </div>
                </div>
                {/* col end */}
                {/* col start */}
                <div className="col-lg-2 col-md-6 col-sm-12 col-12">
                  <div className="footer_headti">
                    <h5><img src="" alt="" className="img-fluid" /> Quick Links</h5>
                    <ul>
                      <li><a href="/"> <i className="bx bx-spa" /> Home</a></li>
                      <li><a href="about"> <i className="bx bx-spa" /> About Us</a></li>
                      {/* <li><a href="services"> <i className="bx bx-spa" /> Services</a></li> */}
                      <li><a href="team"> <i className="bx bx-spa" /> Our Clients</a></li>
                      {/*<li><a href="javascript:void();"> <i class='bx bx-spa'></i> Brochure</a></li>*/}
                      <li><a href="blog"> <i className="bx bx-spa" /> Why Us</a></li>
                      <li><a href="sitemap"> <i className="bx bx-spa" /> Sitemap</a></li>
                      <li><a href="contact"> <i className="bx bx-spa" /> Contact Us</a></li>
                    </ul>
                  </div>
                </div>
                {/* col end */}
                {/* col start */}
                <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                  <div className="footer_headti">
                    <h5><img src="" alt="" className="img-fluid" /> Services</h5>
                    <ul>
                      <li><a href="interior"> <i className="bx bx-spa" />Architecture/Interior</a></li>
                      <li><a href="planning"> <i className="bx bx-spa" /> Design & Planning</a></li>
                      <li><a href="modularkitchen"> <i className="bx bx-spa" /> Modular Kitchen</a></li>
                      <li><a href="location"> <i className="bx bx-spa" />Residential</a></li>
                      <li><a href="commercial"> <i className="bx bx-spa" /> Commercial</a></li>
                    </ul>
                  </div>
                </div>
                {/* col end */}
                {/* col start */}
                <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                  <div className="footer_headti">
                    <h5><img src="" alt="" className="img-fluid" /> Contact Us</h5>
                    {/* media div start */}
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0">
                        <i className="bx bx-map" />
                      </div>
                      <div className="flex-grow-1 ms-2">
                        <div className="footer_num_div address-info">
  <p>504, B wing, Statesman House, 148 Barakhamba
    Road, New Delhi 110001 (INDIA)</p>
</div>
                      </div>
                    </div>
                    {/* media div end */}
                    {/* media div start */}
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0">
                        <i className="bx bxs-phone-call" />
                      </div>
                      <div className="flex-grow-1 ms-2">
                        <div className="footer_num_div">
                          <a href="tel: +91-9971400633">  +91-9971400633</a>
                          <a href="tel: +91-9899953669">  +91-9899953669</a>
                        </div>
                      </div>
                    </div>
                    {/* media div end */}
                    {/* media div start */}
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0">
                        <i className="bx bx-envelope-open" />
                      </div>
                      <div className="flex-grow-1 ms-2">
                        <div className="footer_num_div">
                          <a href="mailto:Mathurpooja26@Gmail.Com" className="email-link">mathurpooja26@gmail.Com</a>
                          <a href="mailto:Aakar.Design26@Gmail.Com" className="email-link">aakar.design26@gmail.Com</a>
                        </div>
                      </div>
                    </div>
                    {/* media div end */}
                    {/* social icon div */}
                    <div className="footer_icon_div">
                      <b className="bx bx-share-alt" />
                      {/* <a href="/" className="bx bxl-facebook" />
                <a href="/" className="bx bxl-instagram" /> */}
                      {/*<a href="javascript:void();" class="bx bxl-youtube"></a>*/}
                    </div>
                    {/* social icon div  end*/}
                  </div>
                </div>
                {/* col end */}
              </div>
            </div>
          </div>

        </footer>
        {/* ===================================copyright section start================= */}
        <section className="copyright_section">
          <div className="container">
            <div className="row">
              {/* col start  */}
              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <p>Copyright ©️ 2024 Aakar Design Consultan. All Right Reserved. Design by <a href="https://www.webflowindia.com/" target="_blank" rel="noopener noreferrer">Web Flow India</a>.</p>

              </div>
              {/* col end */}
            </div>
          </div>
        </section>
        {/* ===================================copyright section end================= */}
      </div>

    </>
  )
}

export default Footer

import React from "react"
import Back from "../common/Back"
// import RecentCard from "../home/Services/RecentCard"
// import Location from "../../components/home/Why us/Awards"
import "../home/Services/recent.css"
import img from "../images/4.jpeg"
import Awards from "../../components/home/Why us/Awards"

const Blog = () => {
  return (
    <>
      <section className='blog-out mb'>
        <Back name='' title='Aakar Design Consultant ' cover={img} />
        <div className='container recent'>
        </div>
          <Awards />
      </section>
    </>
  )
}

export default Blog

import React, { useState } from "react"
import "./header.css"
import { nav } from "../../data/Data"
import logo from "./image.png"
// import { Link } from "react-router-dom"
// //for local storage use anchor tag instead of Link
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faFacebook, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
// import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'

const Header = () => {
  const [navList, setNavList] = useState(false)

  return (
    <>
      {/* <header class="header">
        <div class="logo">

          <a className="logono" href="tel: +91-9810559445">
            <FontAwesomeIcon icon={faPhone} /> +91-9810559445
          </a>
          <a className="logono" href="mailto:aakar.design26@gmail.com">
            <FontAwesomeIcon icon={faEnvelope} /> GSTIN/UIN

            : 07ASBPM1542Q2ZG
          </a>

          <a className="logono" href="mailto:aakar.design26@gmail.com">
            <FontAwesomeIcon icon={faEnvelope} /> aakar.design26@gmail.com
          </a>
        </div>

        <nav className="nav-links">
          <a href="https://www.facebook.com/yourusername" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFacebook} />
          </a>
          <a href="https://www.instagram.com/yourusername" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a href="https://wa.me/yourphonenumber" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faWhatsapp} />
          </a>
        </nav>
      </header> */}

      <header>
        <div className='container flex'>
          <div className='logo'>
          <a href="/">
  <img src={logo} alt="Aakar" />
</a>
          </div>
          <div className='nav'>
            <ul className={navList ? "small" : "flex"}>
              {nav.map((list, index) => (
                <li key={index}>
                  {/* <Link to={list.path}>{list.text}</Link> */}
                  <a href={list.path}>{list.text}</a>
                </li>
              ))}
            </ul>
          </div>
         

          <div className='toggle'>
            <button className="btn mob" onClick={() => setNavList(!navList)}>{navList ? <i className='fa fa-times'></i> : <i className='fa fa-bars'></i>}</button>
          </div>
        </div>
      </header>
    </>
  )
}

export default Header

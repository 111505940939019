export const nav = [
  {
    text: "home",
    path: "/",
  },
  {
    text: "about",
    path: "/about",
  },
  {
    text: "services",
    path: "/services",
  },
  {
    text: "Site Map",
    path: "/sitemap",
  },
  {
    text: "Residential",
    path: "/location",
  },
  {
    text: "Commercial",
    path: "/commercial",
  },
  {
    text: "contact",
    path: "/contact",
  },
]
export const featured = [
  {
    cover: "../images/hero/h1.png",
    name: "Family House",
    // total: "122 Property",
  },
  {
    cover: "../images/hero/h2.png",
    name: "House & Villa",
    // total: "155 Property",
  },
  {
    cover: "../images/hero/h3.png",
    name: "Apartment",
    // total: "300 Property",
  },
  {
    cover: "../images/hero/h4.png",
    name: "Office & Studio",
    // total: "80 Property",
  },
  {
    cover: "../images/hero/h6.png",
    name: "Villa & Condo",
    // total: "80 Property",
  },
]
//services-----------------------------------------------------------
export const list = [
  {
    id: 1,
    cover: "../images/3.jpeg",
    location: "210 Zirak Road, Canada",
    text: "Experience top-notch architectural services with our team of design consultants. Let's bring your dream home or office space to life.",
    type: "Architecture/Interior",
    path:"/Interior"
  },
  {
    id: 2,
    cover: "../images/14.jpeg",
    location: "5698 Zirak Road, NewYork",
    text: "Specializing in modern and functional kitchen spaces, we're ready to help you design your dream kitchen.",
    type: "Modular Kitchen",
    path:"/ModularKitchen"
  },
  {
    id: 3,
    cover: "../images/9.jpeg",
    location: "5624 Mooker Market, USA",
    text: "Led by experienced design consultants, our planning services are here to help you design your dream home or office space.",
    type: "Planning ",
    path:"/Planning"
  },
  {
    id: 4,
    cover: "./images/interior/10.png",
    location: "5621 Liverpool, London",
    text: "Exceptional interior designing services are at your fingertips. Let's work together to design your dream home or office space.",
    type: "Interior Designing",
    path:"/Interior"
  },
  {
    id: 5,
    cover: "../images/1.jpeg",
    location: "210 Montreal Road, Canada",
    text: "Excel in commercial space planning and design with our team. We're here to help you design your dream office space.",
    type: "Commercial",
    path:"/commercial"
  },
  {
    id: 6,
    // cover: "https://aakardesignconsultants.in/assets/IMG_20191006_170446.jpg",
    cover: "https://dzdae.com/wp-content/uploads/2023/10/1-3.png",
    location: "210 Zirak Road, Canada",
    text: "With top-tier services from our residential design consultants, let's design your dream home together.",
    type: "Residential",
    path:"/location"
  },
]
//why us -----------------------------------------------------------
export const awards = [
  {
    icon: <i class='fa fa-handshake'></i>,
    num: "Meet	",
    name: "Meet our expert design team and let them know your requirements.",
  },
  {
    icon: <i class='fa-solid fa-shopping-cart'></i>,
    num: "Order",
    name: "Place your order and we'll get to work right away with our state-of-the-art facilities.",
  },
  {
    icon: <i class='fa fa-pencil-alt'></i>,
    num: "Planning",
    name: "Our design team will create a custom design based on your requirements.",
  },
  {
    icon: <i class='fa fa-building'></i>,
    num: "Build Design",
    name: "By our professional and expert logistic partners",
  },
]
//Residential-----------------------------------------------------------
export const location = [
  {
    id: 1,
    name: "New Orleans, Louisiana",
    Villas: "12 Villas",
    Apartments: "10 Apartments",
    Offices: "07 Offices",
    cover: "https://dzdae.com/wp-content/uploads/2023/10/1-3.png",
  },
  {
    id: 2,
    name: "Jerrsy, United State",
    Villas: "12 Villas",
    Apartments: "10 Apartments",
    Offices: "07 Offices",
    cover: "https://sumesshmenonassociates.com/wp-content/uploads/2021/09/kingfisher-tower-1920X1080_0000_13.jpg.jpg",
  },
  {
    id: 3,
    name: "NewYork, United States",
    Villas: "12 Villas",
    Apartments: " 10 Apartments",
    Offices: "07 Offices",
    cover: "./images/14.jpeg",
  },
  {
    id: 4,
    name: "Liverpool, London",
    Villas: "12 Villas",
    Apartments: " 10 Apartments",
    Offices: "07 Offices",
    cover: "https://www.andacademy.com/resources/wp-content/uploads/2024/03/2-13.webp",
  },
  {
    id: 5,
    name: "Montreal, Canada",
    Villas: "12 Villas",
    Apartments: " 10 Apartments",
    Offices: "07 Offices",
    cover: "https://5.imimg.com/data5/SELLER/Default/2023/3/296953933/EC/HK/MH/152709595/classic-residential-interior-design-service-500x500.jpeg",
  },
  {
    id: 6,
    name: "California, USA",
    Villas: "12 Villas",
    Apartments: " 10 Apartments",
    Offices: "07 Offices",
    cover: "https://thearchitectsdiary.com/wp-content/uploads/2023/01/Prana-Design-Studio-6-1024x800.jpg",
  },
]
//commercial-----------------------------------------------------------
export const commercial = [
  {
    id: 1,
    name: "New Orleans, Louisiana",
    Villas: "12 Villas",
    Apartments: "10 Apartments",
    Offices: "07 Offices",
    cover: "./images/1.jpeg",
  },
  {
    id: 2,
    name: "Jerrsy, United State",
    Villas: "12 Villas",
    Apartments: "10 Apartments",
    Offices: "07 Offices",
    cover: "https://i.pinimg.com/736x/81/71/de/8171de654a15bd1cdc75de74f22444a8.jpg",
  },
  {
    id: 3,
    name: "Liverpool, London",
    Villas: "12 Villas",
    Apartments: " 10 Apartments",
    Offices: "07 Offices",
    cover: "./images/2.jpeg",
  },
  {
    id: 4,
    name: "NewYork, United States",
    Villas: "12 Villas",
    Apartments: " 10 Apartments",
    Offices: "07 Offices",
    cover: "https://thearchitecturedesigns.com/wp-content/uploads/2022/08/Commercial-Interior-Designer-2-759x500.jpg",
  },
  {
    id: 5,
    name: "California, USA",
    Villas: "12 Villas",
    Apartments: " 10 Apartments",
    Offices: "07 Offices",
    cover: "./images/3.jpeg",
  },
  {
    id: 6,
    name: "Montreal, Canada",
    Villas: "12 Villas",
    Apartments: " 10 Apartments",
    Offices: "07 Offices",
    cover: "https://www.latestinteriors.com/wp-content/uploads/2023/01/Commercial.jpg",
  },
]
//clients-----------------------------------------------------------
export const team = [
  {
    name: "Mr. Rakesh Khanna ",
    testimonial: "Designed their office in Patel Nagar & Noida. (Approx 4000 sqft.)",
    cover: "https://cdn2.iconfinder.com/data/icons/male-users-2/512/2-512.png",
  },
  {
    name: "Mr. Sunil Yadav ",
    testimonial: "Designed his bungalow in Gurgaon (Approx 8000 sqft.)",
    cover: "https://cdn2.iconfinder.com/data/icons/male-users-2/512/2-512.png",
  },
  {
    name: "Mr. Gurmeet Baweja ",
    testimonial: "Designed his bungalow in Gurgaon (Approx 10000 sqft.)",
    cover: "https://cdn2.iconfinder.com/data/icons/male-users-2/512/2-512.png",
  },
  {
    name: "Indian Cancer Society",
    testimonial: "Executing their office at South Ex as turnkey project. (Approx 3200 sqft.)",
    cover: "https://cdn2.iconfinder.com/data/icons/male-users-2/512/2-512.png",
  },
  {
    name: "Two Apartments in Magnolia DLF",
    testimonial: "Designed many apartments while working with a builder in South Delhi.",
    cover: "https://cdn2.iconfinder.com/data/icons/male-users-2/512/2-512.png",
  },
  {
    name: "Boutique Showroom",
    testimonial: "Designed a boutique showroom. (Approx 4500 sqft)",
    cover: "https://cdn2.iconfinder.com/data/icons/male-users-2/512/2-512.png",
  },
  {
    name: "Mr. Praveen Yadav ",
    testimonial: "Designed a residence at Palam Vihar",
    cover: "https://cdn2.iconfinder.com/data/icons/male-users-2/512/2-512.png",
  },
  {
    name: "Callista Group Realtors",
    testimonial: "Designed as consultants for residential projects in Palam Vihar Gurgaon.",
    cover: "https://cdn2.iconfinder.com/data/icons/male-users-2/512/2-512.png",
  },
  {
    name: "Raj Properties",
    testimonial: "Designed as consultants for residential projects",
    cover: "https://cdn2.iconfinder.com/data/icons/male-users-2/512/2-512.png",
  },
  {
    name: "Kapish Jain & Associates",
    testimonial: "Designed a commercial space. (2500 sqft)",
    cover: "https://cdn2.iconfinder.com/data/icons/male-users-2/512/2-512.png",
  },
  {
    name: "Mr. Vikas Verma",
    testimonial: "3 BHK complete transformation/renovation - Residential",
    cover: "https://cdn2.iconfinder.com/data/icons/male-users-2/512/2-512.png",
  },
];

export const footer = [
  {
    title: "Useful Links",
    text: [{ list: "Home" }, { list: "About us" }, { list: "Service" }, { list: "Why us" }, { list: "Contact us" }],
  },
  // {
  //   title: "ALL SECTIONS",
  //   text: [{ list: "Gallery" }, { list: "Features" }, { list: "Attractive" }, { list: "Testimonials" }, { list: "Videos" }],
  // },
  {
    title: "Address",
    text: [{ list: "198 Munirka Vihar, New Delhi (110067) INDIA" }, { list: "+91-9810559445" }, { list: "+91-9811110775" }, { list: "Mathurpooja26@Gmail.Com" }, { list: "Aakar.Design26@Gmail.Com" }],
  },
]

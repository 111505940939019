import React from "react"
import Heading from "../common/Heading"
import img2 from "../images/1.jpg"
import "./about.css"
// import Img from "./Img"


const About = () => {
  return (
    <>
      <section className='about'>
    {/* <Img /> */}
        <div className='container flex mtop'>
          <div className='left row'>
            <Heading title='About Us' subtitle='Aakar Design Consultants' />

            <p>Aakar Design Consultants is a renowned architecture and interior design firm known for its innovative and contemporary designs. Established in 2017, they have built a strong reputation for creating visually stunning and functional spaces across various sectors including residential, commercial, hospitality, and cultural.</p>
<p>Their design philosophy often incorporates a seamless integration of modern aesthetics with practical functionality, emphasizing the use of sustainable materials and environmentally conscious practices. Aakar Design Consultants are known for their attention to detail, commitment to client satisfaction, and ability to deliver projects that exceed expectations.</p>
            <a href='/contact' >
            <button className='btn2'>Get in Touch</button>
            </a>
          </div>
          <div className='right row'>
           <img src={img2} alt='' className='about-image' />
          </div>
        </div>
      </section>
    </>
  )
}

export default About

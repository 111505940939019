import React from "react"
import Header from "../common/header/Header"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import Home from "../home/Home"
import Footer from "../common/footer/Footer"
import About from "../about/About"
// import Pricing from "../pricing/Pricing"
import Blog from "../work/Blog"
import Services from "../services/Services"
import Contact from "../contact/Contact"
import Location from "../../components/home/Gallery/Location"
import team from "../../components/home/testimonials/Team"
import Commercial from "../home/Gallery/Commercial"
import Interior from "../home/Gallery/Interior"
import ModularKitchenComponent from "../home/Gallery/ModularKitchen"
import Planning from "../home/Gallery/Planning"
import Sitemap from "../Sitemap/Sitemap"


const Pages = () => {
  return (
    <>
      <Router>
        <Header />
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/about' component={About} />
          <Route exact path='/services' component={Services} />
          <Route exact path='/blog' component={Blog} />
          {/* <Route exact path='/pricing' component={Pricing} /> */}
          <Route exact path='/contact' component={Contact} />
          <Route exact path='/team' component={team} />
          <Route exact path='/commercial' component={Commercial} />
          <Route exact path='/location' component={Location} />
          <Route exact path='/interior' component={Interior} />
          <Route exact path='/ModularKitchen' component={ModularKitchenComponent} />
          <Route exact path='/planning' component={Planning} />
          <Route exact path='/sitemap' component={Sitemap} />
        </Switch>
        <Footer />
      </Router>
    </>
  )
}

export default Pages

import React from 'react'
import Heading from "../../common/Heading"
import { Planning as planningData } from "../../data/services"
import "./style.css"

const Planning = () => {
  return (
    <div>
      <section className='location padding'>
        <div className='container'>
          <Heading title='Planning' subtitle='' />
          <p className="planning-text" style={{ textAlign: 'justify' }}>
            Our team of architects and designers specialize in planning functional and visually appealing spaces. We cater to a variety of design needs, from residential to commercial planning.
          </p>

          <div className='content grid3 mtop mobile-view'>
            {planningData.map((item, index) => (
              <div className='box' key={index}>
                <img src={item.cover} alt='' />
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  )
}

export default Planning